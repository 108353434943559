
@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '~assets/styles/constant';

.index {
	.title{
		letter-spacing: 2px;
		padding: 5px;
		padding-left: 24px;
		border-left: 6px solid $secondary-color;
		font-weight: 600;
	}
	.social-button-link {
		color: black;
		transition: opacity 0.3s ease;
		font-size: 3rem;
		letter-spacing: 2rem;

		&:hover{
			color: black;
			opacity: 0.6;
		}
	}
}
