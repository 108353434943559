
@import '~assets/styles/constant';
@import '~breakpoint-sass/stylesheets/_breakpoint';


.cover-avatar {
	animation: 0.5s cubic-bezier(0.21, 0.15, 0.01, 0.9) 0s 1 pop-up;

	width: $cover-avatar-height-mobile;
	height: $cover-avatar-height-mobile;
	display: inline-block;
	background: white;
	border-radius: 50%;
	@include breakpoint($sm){
		width: $cover-avatar-height;
		height: $cover-avatar-height;
	}
	border: 6px solid white;
}
