@import url(https://fonts.googleapis.com/css?family=Kanit|Noto+Sans+TC);
@-webkit-keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@-webkit-keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@-webkit-keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@-webkit-keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

.app-nav {
  background: white;
  box-shadow: 1px 0px 20px #ababab; }
  @media (min-width: 0) {
    .app-nav {
      height: 50vh;
      padding-top: calc(50vh - 50px); } }
  @media (min-width: 567px) {
    .app-nav {
      height: 52px;
      padding-top: 0px;
      margin-bottom: 15px; } }

.nav-item {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: black;
  letter-spacing: 1px;
  display: inline-block;
  line-height: 52px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .nav-item.active {
    line-height: 42px;
    border-bottom: 5px solid #3F51B5; }
  @media (min-width: 567px) {
    .nav-item {
      font-size: 16px; }
      .nav-item:hover {
        line-height: 42px;
        background-color: #E0E0E0;
        border-bottom: 5px solid #E91E63; } }

footer {
  height: 52px;
  line-height: 52px;
  text-align: center;
  background-color: black;
  color: white; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@-webkit-keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@-webkit-keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@-webkit-keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

.cover-avatar {
  -webkit-animation: 0.5s cubic-bezier(0.21, 0.15, 0.01, 0.9) 0s 1 pop-up;
          animation: 0.5s cubic-bezier(0.21, 0.15, 0.01, 0.9) 0s 1 pop-up;
  width: 180px;
  height: 180px;
  display: inline-block;
  background: white;
  border-radius: 50%;
  border: 6px solid white; }
  @media (min-width: 567px) {
    .cover-avatar {
      width: 200px;
      height: 200px; } }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@-webkit-keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@-webkit-keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@-webkit-keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

.cover {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  background-size: cover;
  background-attachment: fixed; }
  @media (min-width: 0) {
    .cover {
      height: 50vh; } }
  @media (min-width: 567px) {
    .cover {
      height: 400px; } }
  .cover .switch-language-container {
    position: absolute;
    right: 2rem;
    top: 1rem; }

@media (min-width: 0) {
  .cover-wrapper {
    padding-top: calc(50vh - 180px/2); } }

@media (min-width: 567px) {
  .cover-wrapper {
    padding-top: calc((400px - 200px)/2); } }

.cover-wrapper h3 {
  font-weight: 600;
  opacity: 0.87; }

.cover-wrapper h6 {
  font-weight: 600;
  opacity: 0.54; }

@media (min-width: 0) {
  .cover-wrapper .name {
    margin-top: 5vh; } }

@media (min-width: 567px) {
  .cover-wrapper .name {
    margin-top: 10px; } }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@-webkit-keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@-webkit-keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@-webkit-keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

.index .title {
  letter-spacing: 2px;
  padding: 5px;
  padding-left: 24px;
  border-left: 6px solid #E91E63;
  font-weight: 600; }

.index .social-button-link {
  color: black;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  font-size: 3rem;
  letter-spacing: 2rem; }
  .index .social-button-link:hover {
    color: black;
    opacity: 0.6; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@-webkit-keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@-webkit-keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@-webkit-keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

.milestones {
  -webkit-animation: 0.5s cubic-bezier(0, 0.66, 0.25, 1) 0s 1 dropdown;
          animation: 0.5s cubic-bezier(0, 0.66, 0.25, 1) 0s 1 dropdown;
  position: relative;
  padding-bottom: 50px; }
  .milestones:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #B5B5B5; }
    @media (min-width: 768px) {
      .milestones:before {
        left: 50px; } }
  .milestones:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: -15px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #EF6C00; }
    @media (min-width: 768px) {
      .milestones:after {
        left: 36px; } }
  .milestones .event {
    position: relative;
    padding-top: 24px;
    padding-left: 30px; }
    .milestones .event span {
      color: rgba(0, 0, 0, 0.75); }
    @media (min-width: 768px) {
      .milestones .event {
        padding-left: 100px; } }
    .milestones .event:before {
      content: '';
      position: absolute;
      left: -7px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #0c8763; }
      @media (min-width: 768px) {
        .milestones .event:before {
          left: 42px; } }
  .milestones .event.big span {
    font-size: 1.1rem; }
  .milestones .event.big:before {
    left: -14px;
    width: 32px;
    height: 32px;
    border: 5px solid #5b76c3;
    background-color: white; }
    @media (min-width: 768px) {
      .milestones .event.big:before {
        left: 36px; } }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@-webkit-keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@-webkit-keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@-webkit-keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

.gallery .item {
  position: relative;
  text-align: center; }
  .gallery .item img {
    width: 288px;
    height: 162px;
    border: 5px solid #424242;
    display: inline-block; }
  .gallery .item .mask {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 288px;
    height: 162px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    opacity: 0;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease; }
    .gallery .item .mask .mask-overlay {
      position: relative;
      text-align: center;
      padding: 30px;
      top: calc(50% - 90px); }
      .gallery .item .mask .mask-overlay .mask-text {
        max-height: 80px;
        color: white;
        font-size: 14px;
        margin-bottom: 10px; }
      .gallery .item .mask .mask-overlay .mask-icon {
        width: 44px;
        height: 44px;
        color: black;
        font-size: 18px;
        background-color: white;
        padding: 10px;
        border-radius: 50%;
        border: 2px solid #666;
        margin: 3px;
        cursor: pointer;
        display: inline-block;
        line-height: 100%; }
        .gallery .item .mask .mask-overlay .mask-icon a {
          color: black; }
  .gallery .item:hover .mask {
    opacity: 1; }
  .gallery .item:active .mask {
    opacity: 1; }

.gallery .item.popup {
  -webkit-animation: 0.2s ease 1 fade-in;
          animation: 0.2s ease 1 fade-in;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 120vh;
  background-color: black;
  z-index: 1001; }
  .gallery .item.popup img {
    margin-top: calc(50vh - 162px/2 - 100px);
    width: 288px;
    height: 162px; }
    @media (min-width: 768px) {
      .gallery .item.popup img {
        margin-top: calc(50vh - 162px*2/2 - 60px);
        width: 576px;
        height: 324px; } }
    @media (min-width: 992px) {
      .gallery .item.popup img {
        margin-top: calc(50vh - 162px*3/2 - 100px);
        width: 864px;
        height: 486px; } }
  .gallery .item.popup .mask {
    position: relative;
    opacity: 1;
    width: 100%; }
    .gallery .item.popup .mask .mask-text {
      text-align: center;
      color: white;
      font-size: 14px; }

.gallery .popup-close {
  cursor: pointer;
  font-size: 48px;
  color: white; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@-webkit-keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@-webkit-keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@-webkit-keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

.skills .construction {
  text-align: center;
  font-size: 128px; }

.skills .title {
  letter-spacing: 2px;
  padding: 5px;
  padding-left: 24px;
  border-left: 6px solid #E91E63;
  font-weight: 700;
  font-style: italic; }

.skills .card {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  letter-spacing: 1px; }
  .skills .card .thumbnail {
    height: 5rem;
    width: 5rem; }

.skills .card.average {
  box-shadow: inset 0px 0px 0px 2px #FFA726; }

.skills .card.fair {
  box-shadow: inset 0px 0px 0px 2px #9E9E9E; }

.skills .chart-container {
  width: 1000px;
  height: 1000px;
  margin: auto; }
  @media (min-width: 0) {
    .skills .chart-container {
      width: 90vw;
      height: 90vw; } }
  @media (min-width: 567px) {
    .skills .chart-container {
      width: 400px;
      height: 400px; } }
  @media (min-width: 768px) {
    .skills .chart-container {
      width: 600px;
      height: 600px; } }
  @media (min-width: 992px) {
    .skills .chart-container {
      width: 800px;
      height: 800px; } }

.skills svg {
  margin: auto;
  display: block; }

.skills .circle-overlay {
  font-size: 16px;
  border-radius: 50%;
  position: absolute;
  overflow: hidden; }

.skills .circle-overlay__inner {
  text-align: center;
  width: 100%;
  height: 100%; }

.skills .circle-overlay__title {
  text-align: center;
  font-size: 1rem;
  margin-top: 1rem; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }
@keyframes fade-in {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@-webkit-keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes pop-up {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@-webkit-keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@keyframes dropdown {
  0% {
    -webkit-transform: translateY(-50%) scaleY(0);
            transform: translateY(-50%) scaleY(0); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1);
            transform: translateY(0%) scaleY(1); } }

@-webkit-keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes shake-head {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  40% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  80% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@media (min-width: 0) {
  html {
    font-size: 12px; } }

@media (min-width: 768px) {
  html {
    font-size: 16px; } }

body {
  overflow-x: hidden;
  background: #EEEEEE;
  font-family: 'Kanit', sans-serif; }

a:hover, a:focus {
  text-decoration: none; }

h1 {
  margin-top: 20px;
  margin-bottom: 20px; }

.main-content {
  background-color: white;
  min-height: 60vh; }
  .main-content > * {
    -webkit-animation: 0.5s ease 0s 1 fade-in;
            animation: 0.5s ease 0s 1 fade-in; }

.chinese {
  font-family: 'Noto Sans TC', sans-serif, 'Roboto'; }

.weight-light {
  font-weight: lighter; }

.weight-normal {
  font-weight: normal; }

.weight-bold {
  font-weight: bold; }

.weight-bolder {
  font-weight: bolder; }

.italic {
  font-style: italic; }

