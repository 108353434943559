
@import '~assets/styles/constant';
@import '~breakpoint-sass/stylesheets/_breakpoint';

.app-nav {
	@include breakpoint(0, $sm){
		height: $app-nav-height-mobile;
		padding-top: calc(#{$app-nav-height-mobile} - 50px);
	}
	@include breakpoint($sm) {
		height: $app-nav-height;
		padding-top: 0px;
		margin-bottom: 15px;
	}
	background: white;
	box-shadow: 1px 0px 20px #ababab;
}

.nav-item {
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	color: black;
	letter-spacing: 1px;
	display: inline-block;
	line-height: $app-nav-height;
	transition: all 0.3s ease;
	&.active {
		line-height: $app-nav-height - 10px;
		border-bottom: 5px solid $theme-color;
	}
	@include breakpoint($sm){
		font-size: 16px;
		&:hover {
			line-height: $app-nav-height - 10px;
			background-color: #E0E0E0;
			border-bottom: 5px solid $secondary-color;
		}
	}
}
