
@import './constant';
@import '~breakpoint-sass/stylesheets/_breakpoint';
@import url('https://fonts.googleapis.com/css?family=Kanit|Noto+Sans+TC');

html{
	@include breakpoint(0, $md){
		font-size: 12px;
	}
	@include breakpoint($md){
		font-size: 16px;
	}
}

body {
	overflow-x: hidden;
	background: #EEEEEE;
	font-family: 'Kanit', sans-serif;
}

a {
	&:hover, &:focus {
		text-decoration: none;
	}
}

h1{
	margin-top: 20px;
	margin-bottom: 20px;

}

.main-content{
	// only children
	& > * {
		animation: 0.5s ease 0s 1 fade-in;
	}
	background-color: white;
	min-height: 60vh;
}

.chinese { font-family: 'Noto Sans TC', sans-serif, 'Roboto'; }

.weight-light { font-weight: lighter; }
.weight-normal { font-weight: normal; }
.weight-bold { font-weight: bold; }
.weight-bolder { font-weight: bolder; }
.italic { font-style: italic ;}