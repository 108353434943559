
@import '~assets/styles/constant';
@import '~breakpoint-sass/stylesheets/_breakpoint';

.skills{
	.construction {
		text-align: center;
		font-size: 128px;
	}
	.title{
		letter-spacing: 2px;
		padding: 5px;
		padding-left: 24px;
		border-left: 6px solid $secondary-color;
		font-weight: 700;
		font-style: italic;
	}
	.card {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
		letter-spacing: 1px;
		.thumbnail{
			height: 5rem;
			width: 5rem;
		}
	}
	.card.average {  box-shadow: inset 0px 0px 0px 2px #FFA726; }
	.card.fair{ box-shadow: inset 0px 0px 0px 2px #9E9E9E; }
	// chart
	.chart-container {
		@include breakpoint(0, $sm){
			width: 90vw;
			height: 90vw;
		}
		@include breakpoint($sm, $md){
			width: 400px;
			height: 400px;
		}
		@include breakpoint($md, $lg){
			width: 600px;
			height: 600px;
		}
		@include breakpoint($lg, $xl){
			width: 800px;
			height: 800px;
		}
		width: 1000px;
		height: 1000px;
		margin: auto;
	}
	svg {
		margin:auto;
		display:block;
	}
	.circle-overlay {
		font-size: 16px;
		border-radius: 50%;
		position: absolute;
		overflow: hidden;
	}
	.circle-overlay__inner {
		text-align: center;
		width: 100%;
		height: 100%;
	}
	.circle-overlay__title {
		text-align: center;
		font-size: 1rem;
		margin-top: 1rem;
	}
}